import React, { useEffect, useState } from 'react';
import Button from '../common/button';
import { useCookies } from 'react-cookie';

const Cookies = () => {
  const cookieName = 'cookieConsent';
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);
  const [isVisible, setIsVisible] = useState(true);

  const handleAcceptCookies = () => {
    // Store the data in cookies
    setCookie(cookieName, 'accepted');
    setIsVisible(false);
  };

  const handleDeclineCookies = () => {
    // Remove the cookie
    removeCookie(cookieName);
    setIsVisible(false);
  };

  useEffect(() => {
    if (cookies[cookieName] === 'accepted') {
      setIsVisible(false); // Hide the component if the cookie is already set to 'accepted'
    }
  }, [cookies, cookieName]);

  return (
    <>
      {isVisible && (
        <div className="fixed bottom-0 z-50 bg-[#252525]">
          <div className="sm:flex justify-center items-center">
            <div className="text-white p-4">
              <h1 className="flex justify-start pl-6 font-medium  text-base xl:text-lg">
                About cookies on this site
              </h1>
              <p className="text-[13px] xl:text-[16px] text-justify font-thin px-6">
                Our websites require some cookies to function properly
                (required). In addition, other cookies may be used with your
                consent to analyze site usage, improve the user experience, and
                for advertising.
              </p>
            </div>
            <div className="text-[13px] xl:text-[16px] text-justify px-10 pb-4 text-white font-thin pt-0 lg:pt-9 ">
              For more information, please review your cookie preferences
              options. By visiting our website, you agree to our processing of
              information as described in VMA Tech Labs privacy Statement
            </div>
            <div className="flex flex-col justify-center items-center gap-3 mr-2">
              <Button
                className="bg-white text-black px-5 py-2.5"
                onClick={handleAcceptCookies}
              >
                Accept Cookies
              </Button>
              <Button
                className="bg-[#252525] text-white border border-white px-12 py-2"
                onClick={handleDeclineCookies}
              >
                Decline
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cookies;
