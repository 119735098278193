import React from 'react';
import Slider from 'react-slick';
import './slick.css';
import whiteYellowSquareImg from '../../assets/aboutPage/whiteYellowSquareimg.png';
import SectionTtitle from '../common/sectionTtitle';

const content = [
  {
    title: 'Dedicated Center Of Excellence',
    description:
      'Lead your business towards an intelligent future with our 50 Plus world-class SAP experts closely working at VMA Tech Labs Dedicated Center of Excellence to foster continuous improvements.',
  },
  {
    title: 'Dedicated Center Of Excellence',
    description:
      'Lead your business towards an intelligent future with our 50 Plus world-class SAP experts closely working at VMA Tech Labs Dedicated Center of Excellence to foster continuous improvements.',
  },
  {
    title: 'Dedicated Center Of Excellence',
    description:
      'Lead your business towards an intelligent future with our 50 Plus world-class SAP experts closely working at VMA Tech Labs Dedicated Center of Excellence to foster continuous improvements.',
  },
];

const OurExcellence = () => {
  const settings = {
    dots: true,
    vertical: true,
    verticalSwiping: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
  };

  return (
    <>
      <div className="bg-white pt-10 pb-20">
        <SectionTtitle
          className=""
          title="OUR EXCELLENCE"
          description="VMA TECH LABS is an innovative management and technology consulting
        firm. We provide our Clients a comprehensive suite of systems
        integration"
        />
        <div className="grid lg:grid-cols-2 gap-8 md:gap-0 mt-4 md:mt-6 lg:mt-12">
          <div className="flex justify-center lg:justify-end">
            <div className="">
              <img src={whiteYellowSquareImg} alt="img"></img>
            </div>
          </div>
          <div className="flex justify-center md:justify-start ">
            <div className="aboutDots w-[70%] md:w-[60%] lg:w-[70%] xl:w-[60%] !mx-auto md:m-[0px] object-contain lg:mt-[80px] xl:mt-[80px]  ">
              <Slider {...settings}>
                {content.map((content, index) => (
                  <div key={index}>
                    <div className="flex flex-col gap-5">
                      <h2 className="text-center text-[#000000] text-[16px] lg:text-[20px] xl:text-[25px] font-medium">
                        {content.title}
                      </h2>
                      <p className="text-sm lg:text-lg xl:text-xl text-justify">
                        {content.description}
                      </p>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurExcellence;
