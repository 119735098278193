import React, { useEffect, useRef, useState } from 'react';
import applicationImg from '../../../src/assets/services/applicationImg.png';
import vsync from '../../../src/assets/services/vsync.png';
import supplyChainImg from '../../../src/assets/services/supplyChainImg.png';
import AI from '../../../src/assets/services/AI.png';
import infra from '../../../src/assets/services/infra.png';
import product from '../../../src/assets/services/product.png';
import digital from '../../../src/assets/services/digital.png';
import QA from '../../../src/assets/services/QA.png';
import eInvoice from '../../../src/assets/services/eInvoice.png';
import integrationImg from '../../../src/assets/services/integrationImg.png';
import digitalSign from '../../../src/assets/services/digitalSign.png';
import ServiceCards from './serviceCards';
import downArrow from '../../../src/assets/services/downwardArrow.png';
import SectionTtitle from '../common/sectionTtitle';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

const Item = ({
  cardTitile = '',
  description,
  title,
  image,
  setIsExpanded,
  id,
  isExpanded,
  link = '',
}) => {
  const des = useRef('');
  const [divHeight, setDivHeight] = useState();
  const isOpen = isExpanded === id;
  const navigate = useNavigate();
  const toggleDescription = () => {
    setIsExpanded(isOpen ? -1 : id);
  };

  useEffect(() => {
    setDivHeight(des?.current?.clientHeight);
  }, [isOpen]);

  return (
    <div>
      <div
        className={cx('flex flex-col justify-center cursor-pointer', {
          isOpen: 'bg-gradient-to-b from-transparent to-[#E9FAFF] rounded-lg',
        })}
        onClick={() => (link ? navigate(`${link}`) : toggleDescription())}
      >
        <div className="flex justify-center">
          <img className="w-[60%]" src={image} alt="anthibnhg" />
        </div>
        <div>
          <p
            className={`font-medium text-black text-[20px] md:text-[25px] text-center mb-6`}
          >
            {title}
          </p>
          <p
            className={`text-[16px] md:text-[20px] text-black text-center mb-6`}
          >
            {cardTitile || description.split(' ').slice(0, 20).join(' ')}
          </p>
        </div>
      </div>
      <button
        onClick={() => {
          link ? navigate(`${link}`) : toggleDescription();
        }}
        className={`p-4 transition duration-300 ${isOpen ? 'rotate-180' : ''}`}
      >
        <img src={downArrow} alt="downArrow" />
      </button>
      <div className="my-6" style={{ height: divHeight }}>
        {isOpen ? (
          <div ref={des} className="absolute left-0 px-5 sm:px-[65px]">
            <p className=" font-semibold text-[28px] md:text-[40px] text-left mb-2">
              {title}
            </p>
            <div
              className="text-[18px] md:text-[22px] font-medium text-black text-left"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const OurExpertiese = () => {
  const [isExpanded, setIsExpanded] = useState(-1);

  const Carddata = [
    {
      id: 1,
      title: 'SAP Services',
      description:
        ' Support for all technologies, including niche areas such as MDG (Master Data Governance), EWM (Extended Warehouse Management), GRC (Governance, Risk, and Compliance), LE (Logistics Execution), and WM (Warehouse Management). SAP Services: Unleash the power of SAP with our expertise in supporting all technologies. From the most advanced niche technologies like MDG, EWM, GRC, LE, and WM to comprehensive support across the SAP ecosystem, we have you covered. Maximize the efficiency of your business processes with our SAP solutions.',
      image: applicationImg,
    },
    {
      id: 2,
      title: 'Application Development',
      description:
        ' We offer comprehensive web and mobile application development services. Our skilled developers create custom solutions tailored to your specific requirements, ensuring seamless user experiences and optimal functionality. Application Development: Step into the digital realm with confidence. Our skilled developers craft captivating web and mobile applications that captivate your users and drive business growth. Harness the latest technologies to create seamless user experiences and unlock limitless possibilities.',
      image: supplyChainImg,
    },
    {
      id: 3,
      title: 'DevOps',
      description:
        'Our DevOps services enable organizations to streamline their software development and deployment processes. We provide continuous integration, continuous delivery (CI/CD), and automation solutions to enhance agility, efficiency, and collaboration within your development team. DevOps: Amplify your development and deployment capabilities with our DevOps services. We streamline your software delivery pipeline, enabling rapid and reliable releases. Experience the power of continuous integration, continuous delivery, and automation to fuel innovation within your organization',
      image: integrationImg,
    },
    {
      id: 4,
      title: 'AI & ML (Deep Learning)',
      description:
        'With expertise in artificial intelligence (AI) and machine learning (ML), our team leverages deep learning techniques to build intelligent systems. From natural language processing to computer vision, we deliver AI-powered solutions that can automate tasks, extract insights, and drive data-driven decision-making. AI & ML (Deep Learning): Venture into the realm of artificial intelligence and machine learning with our deep learning expertise. Our team harnesses the power of AI and ML algorithms to create intelligent systems that automate tasks, extract valuable insights, and revolutionize decision-making.',
      image: AI,
    },
    {
      id: 5,
      title: 'Infra-realted service',
      description:
        'VMA Tech Labs offers a wide range of infrastructure-related services, including cloud computing, network management, system administration, database management, and security solutions. We ensure that your IT infrastructure is robust, scalable, and secure to support your business operations effectively.Infra-related Services: Trust your infrastructure to the experts. We offer comprehensive infrastructure services that encompass cloud computing, network management, system administration, database management, and security solutions. Your technology backbone will be robust, scalable, and secure, empowering your organization to thrive.',
      image: infra,
    },
    {
      id: 6,
      title: 'Product-related Services',
      description:
        'We provide specialized products and solutions in areas such as Supply Chain Management (SCM), IT Service Management (ITSM), Customer Relationship Management (CRM), Order to Cash (OTC) cycles, and Procure to Pay (P2P). Our products are designed to optimize your business processes and improve overall efficiency. Product Related Services: Optimize your core business areas with our specialized products. From Supply Chain Management (SCM) to IT Service Management (ITSM), Customer Relationship Management (CRM), and beyond, our solutions drive efficiency, enhance productivity, and elevate your business to new heights. ',
      image: product,
    },
    {
      id: 7,
      title: 'Digital Transformation (Industry 5.0) ',
      description:
        'VMA Tech Labs helps organizations navigate the digital era by driving digital transformation initiatives. We assist businesses in leveraging emerging technologies, such as Internet of Things (IoT), blockchain, big data analytics, and automation, to achieve Industry 5.0 standards. Our solutions enable you to stay competitive in the rapidly evolving digital landscape.Digital Transformation (Industry 5.0): Embrace the future with our digital transformation services. We guide organizations through the ever-changing digital landscape, helping you navigate emerging technologies such as IoT, blockchain, big data analytics, and automation. Stay ahead of the curve and redefine your industry with Industry 5.0 standards.  ',
      image: digital,
    },
    {
      id: 8,
      title: 'QA (Automation) Services',
      description:
        'We offer comprehensive quality assurance (QA) services, with a focus on automation. Our skilled QA engineers leverage industry-leading tools and frameworks to perform thorough testing, ensuring your applications quality, reliability, and performance. By automating repetitive test cases, we accelerate your testing cycles and reduce time-to-market. QA (Automation) Services: Ensure flawless performance with our QA expertise. Our skilled engineers meticulously test your applications, leveraging the power of automation to accelerate testing cycles and reduce time-to-market. Unleash a new level of quality, reliability, and customer satisfaction. ',
      image: QA,
    },
    {
      id: 9,
      title: 'E-Invoice',
      cardTitile:
        'Our E-Invoice Integration Solution simplifies and streamlines the electronic invoicing process for businesses using multiple ERP (Enterprise Resource Planning) systems.',
      description:
        '<div> This solution offers the following key features: <div></div> <br> <li> Seamless Integration: Effortlessly connect and integrate e-invoicing capabilities with various ERP systems used across your organization. </li> <li> Multi-ERP Compatibility:This solution is designed to work with a range of ERP platforms, ensuring flexibility and compatibility with your existing software. </li> <li> Centralized Invoicing: Consolidate invoicing processes across all ERPs into a centralized system for improved efficiency and control. </li> <li> Automated Data Sync :Real-time data synchronization between ERP systems and the e-invoicing solution, reducing manual data entry and errors. </li> <li>Compliance Management: Stay compliant with invoicing regulations across different regions or countries, with the solution adapting to diverse tax and legal requirements. </li> <br> <div> <div>And benefits are:</div><br /> <li> Efficiency: Streamline invoicing processes by eliminating the need to manually reconcile data across multiple ERPs. </li> <li> Accuracy : Minimize errors associated with duplicate data entry, ensuring accurate and consistent invoicing. </li> <li> Cost Savings: Reduce operational costs by optimizing invoicing workflows and minimizing the need for custom integrations. </li> <li> Centralized Control : Maintain centralized control over your invoicing operations, enhancing visibility and accountability. </li> <li> Scalability: Easily scale the solution as your business grows or integrates new ERP systems. </li> <li> Compliance: Ensure compliance with local and international invoicing regulations across all ERPs, reducing the risk of fines or penalties. </li> <br> Our E-Invoice Integration Solution for Multiple ERP Systems across multiple countries empowers your organization to efficiently manage e-invoicing across diverse ERP platforms, promoting accuracy, compliance and cost savings. </div> </br>',
      image: eInvoice,
      link: '/e-invoice',
    },
    {
      id: 10,
      title: 'Vsync',
      cardTitile:
        'Connect, collaborate, and manage your business with smart AI-powered technology, valuable insights, and vendor management.',
      image: vsync,
      link: '/products/vsync',
    },
    {
      id: 10,
      title: 'Digital Signature',
      cardTitile:
        'Enhance the security of document signing process with our digital signature across various ERP systems',
      image: digitalSign,
      link: '/digitalSign',
    },
  ];

  return (
    <div className=" w-[86%] mx-auto pt-[180px]">
      <div>
        <SectionTtitle
          title="OUR EXPERTISE"
          description="Welcome to VMA Tech Labs! We are a leading technology solutions
          provider offering a wide range of services to meet your business
          needs. Our team of experts specializes in various domains and
          technologies, ensuring top-notch solutions for your organization.
          Below are the services provided by VMA Tech Labs:"
        />
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {Carddata.map((data) => (
          <div key={data.id}>
            <div className="text-center">
              <Item
                key={data.id}
                id={data.id}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                {...data}
              />
            </div>
          </div>
        ))}
      </div>
      <div className="py-16">
        <ServiceCards />
      </div>
    </div>
  );
};

export default OurExpertiese;
