import React from 'react';
import Slider from 'react-slick';
import firstslide from '../../assets/empowerWorks/firstImg.png';
import secondslide from '../../assets/empowerWorks/secondimg.png';
import thirdslide from '../../assets/empowerWorks/thirdimg.png';
import nextArrow from '../../assets/empowerWorks/nextArrow.png';
import previousArrow from '../../assets/empowerWorks/previousArrow.png';
import SectionTtitle from '../common/sectionTtitle';

function SampleNextArrow({ onClick }) {
  return (
    <div
      className="arrow arrow-right w-[50px] absolute top-[50%] right-[10%] 2xl:right-[12%] cursor-pointer z-10"
      onClick={onClick}
    >
      <div className="">
        <img src={nextArrow} alt="nextArrow" />
      </div>
    </div>
  );
}

function SamplePrevArrow({ onClick }) {
  return (
    <div
      className="arrow arrow-left w-[50px] absolute top-[50%] left-[11%] 2xl:left-[13%] opacity-[60] cursor-pointer z-10"
      onClick={onClick}
    >
      <div className="">
        <img className="" src={previousArrow} alt="previousArrow" />
      </div>
    </div>
  );
}

const images = [
  {
    image: firstslide,
    title: 'Empowering Success',
    description:
      'Comprehensive SAP services empowering financial independence and personal fulfillment. Gain confidence through meaningful employment, covering expenses and enjoying leisure activities with pride. ',
  },
  {
    image: secondslide,
    title: 'Inclusivity and Diversity',
    description:
      'Join our supportive and inclusive community, witness individuals with disabilities thriving, and experience personal fulfillment and growth in forward-thinking enterprises. ',
  },
  {
    image: thirdslide,
    title: 'Fueling Growth through Innovation ',
    description:
      'VMA TECH LABS cultivates a culture of collaboration, embracing diverse perspectives and open dialogue. Encouraging employees to think creatively, challenge norms, and collaborate for groundbreaking solutions. ',
  },
];

const EmpoweringWork = () => {
  const settings = {
    line: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <section id="work">
      <div className=" pt-10 md:pt-20 pb-20 bg-white ">
        <SectionTtitle
          title="Our Working environment"
          description="VMA's empowering work environment ,where innovation thrives and collaboration fuels seccess. experience a dynamic atmosphere that nurtures growth out the best in everyone"
        />
        <div className="flex sm:hidden flex-col gap-3 w-[90%] mx-auto">
          {images?.map(({ image, title = '' }, index) => (
            <div key={index}>
              <div className="relative">
                <img src={image} alt="previousArrow" />
                <div className="absolute top-0 w-full h-full bg-black/30" />
                <p className="absolute bottom-0 text-white text-sm font-semibold text-center w-full mb-2 px-5">
                  {title}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="w-[100%] mt-8 hidden sm:block">
          <Slider {...settings}>
            {images.map((img, index) => (
              <div className="relative object-contain" key={index}>
                <div
                  className="w-[98%] m-auto flex justify-center overflow-hidden"
                  key={index}
                >
                  <img src={img.image} alt="img" className="object-contain " />
                </div>
                <div className=" absolute top-[20%] md:top-[50%] left-[10%]   w-[80%] m-auto flex flex-col lg:gap-4 xl:gap-6 text-center text-white">
                  <div className=" text-[10px] md:text-[26px] lg:text-[26px] xl:text-[38px] object-contain  ">
                    {img.title}
                  </div>
                  <div className="text-[#FFFFFF]  text-[8px] md:text-[14px]  lg:text-[16px] xl:text-[20px] flex ">
                    {img.description}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default EmpoweringWork;
